import './info2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faLink, faComments, faMoneyBillTransfer, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Import the regular user icon
import React, { useState, useEffect} from 'react';

// <k>/tip</k> & <span>/burn</span>Bot.
function Info2() {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(true); // State to control the visibility of slider buttons
  const [showSlider] = useState(true); // Control whether to show slider content or not
  const paragraphStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  };

  const contentData = [
      {
        title: "TipBot",
        text: "Our most famous utility - allowing you to tip crypto currencies to your community and friends across Telegram!\n/tip individual users directly\n"+"/rain"+" on multiple users at once based on recent activity in your group.\nList your own tokens\nFree to use in any group",
        imageUrl: "images/chat.png",
        buttons: [
          {
            text: "Explore the bot",
            onClick: () => {
              // onClick handler for button 2
              window.location.href = "https://t.me/Dogetools_tipbot"; // Replace "URL_of_your_choice" with the desired URL
            }
          }
        ]
      },
      {
        title: "BurnBot",
        text: "Burn them all - reduce your supply within your groups or private chats.\n/burn listed tokens from your tip wallet\n"+"/plot"+" the burn history of burned tokens\n/burned check burned supply, worth and useful stats around your tokens\nFree to use in any group",
        imageUrl: "images/chatKopie.png",
        firstWordClassName: "first-word-style2", // Class name for styling the first word of content 1
        buttons: [
          {
            text: "Explore the bot",
            onClick: () => {
              // onClick handler for button 1
              window.location.href = "https://t.me/Dogetoolsburnbot"; // Replace "URL_of_your_choice" with the desired URL
            }
          }
        ]
      },
      {
        title: "NFT TipBot",
        text: "Our latest addition - enabling users to tip NFTs on Telegram.\n/tipnft to other users\n/mint your NFT collection through the bot. (Fully minted collections are not mintable)\nFree to use with featured collections.\n/withdraw without importing it to your wallet",
        imageUrl: "images/nfttipchat.png",
        buttons: [
          {
            text: "Explore the bot",
            onClick: () => {
              // onClick handler for button 1
              window.location.href = "https://t.me/DogeToolsNftDCTipBot"; // Replace "URL_of_your_choice" with the desired URL
            }
          }
        ]
      },
      {
        title: "Staking",
        imageUrl: "images/staking.png",
        text: "Stake your $DTOOLS tokens while we are building and earn from it.\n5% APY\nWithout lock period, unstake or claim whenever you want, no withdrawal fee after 24h.\nStake for $DTOOLS rewards.",
        buttons: [
          {
            text: "Stake",
            onClick: () => {
              // onClick handler for button 2
              window.location.href = "https://stake.dogetools.dog"; // Replace "URL_of_your_choice" with the desired URL
            }
          }
        ]
      }
      // Add more content objects as needed
    ];
  
    const handlePrev = () => {
      setActiveContentIndex(prevIndex => (prevIndex === 0 ? contentData.length - 1 : prevIndex - 1));
      scrollToAnchor();
    };
  
    const handleNext = () => {
      setActiveContentIndex(prevIndex => (prevIndex === contentData.length - 1 ? 0 : prevIndex + 1));
      scrollToAnchor();
    };
  
    const scrollToAnchor = () => {
      const anchor = document.getElementById('slider-anchor');
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
      const handleResize = () => {
        // Check window width and set showButtons accordingly
        if (window.innerWidth <= 600) {
          setShowButtons(false);
        } else {
          setShowButtons(true);
        }
      };
  
      // Listen for window resize events
      window.addEventListener('resize', handleResize);
  
      // Initial check on mount
      handleResize();
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div className="slider-wrapper" id="info">
    <h1 style={{ marginBottom: '1rem', fontSize:'4rem', letterSpacing:'-2px', fontWeight:'bold', fontFamily:'CustomFont' }} id="slider-anchor">Meet the <span style={{ background: 'linear-gradient(to bottom right, rgb(0, 227, 102), rgb(0, 100, 35))', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>Tools</span>:<br /></h1>

    <div className="slider-container">
        {showButtons ? (
          <div className="slider-content show">
            <div className="content-wrapper" >
              {/* Image */}
              <div className="image" style={{ display: window.innerWidth <= 600 && activeContentIndex !== 3 && activeContentIndex !== 4 ? 'none' : 'block' }}>
                <img src={contentData[activeContentIndex].imageUrl} alt={contentData[activeContentIndex].title} />
              </div>

              {/* Text */}
              <div className="text">
                <h1>{contentData[activeContentIndex].title}</h1>
                {contentData[activeContentIndex].text.split('\n').map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split(' ').map((word, wordIndex, words) => (
                      <span key={wordIndex} className={(wordIndex === 0 && index !== 0) ? "first-word" : ""}>
                        {word}
                        {wordIndex !== words.length - 1 && ' '}
                      </span>
                    ))}
                  </p>
                ))}
                {/* Buttons */}
                {contentData[activeContentIndex].buttons && contentData[activeContentIndex].buttons.map((button, index) => (
                  <button key={index} onClick={button.onClick}>
                    {button.text}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          contentData.map((data, index) => (
            <div key={index} className={`slider-content ${index === activeContentIndex ? 'show' : ''}`}>
              <div className="content-wrapper">
                {/* Image */}
                <div className="image" style={{ display: window.innerWidth <= 600 ? 'block' : 'block' }}>
                  <img src={data.imageUrl} alt={data.title} />
                </div>

                {/* Text */}
                <div className="text">
                  <h1>{data.title}</h1>
                  {data.text.split('\n').map((paragraph, pIndex) => (
                    <p key={pIndex}>
                      {paragraph.split(' ').map((word, wIndex, words) => (
                        <span key={wIndex} className={(wIndex === 0 && pIndex !== 0) ? "first-word" : ""}>
                          {word}
                          {wIndex !== words.length - 1 && ' '}
                        </span>
                      ))}
                    </p>
                  ))}
                  {/* Buttons */}
                  {data.buttons && data.buttons.map((button, bIndex) => (
  <button key={bIndex} onClick={button.onClick}>
    {button.text}
  </button>
))}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    {showButtons && (
        <div className="slider-buttons" style={{ display: showSlider ? 'flex' : 'none'}}>
          <button onClick={handlePrev} style={{ background: "none" }}><FontAwesomeIcon icon={faArrowLeft} style={{ color: 'white', width: '2rem', height: '2rem' }} /></button>
          <button onClick={handleNext} style={{ background: "none" }}><FontAwesomeIcon icon={faArrowRight} style={{ color: 'white', width: '2rem', height: '2rem' }} /></button>
        </div>
    )}
      <div className='grid-item'><h1 style={{fontSize:'3rem', marginBottom:'0.5rem'}}>Stats and numbers:</h1></div>
        <div className='grid' style={{padding: window.innerWidth < 786 ? '1rem':'none', textAlign:"center"}}>
            <div className='grid-item' style={{padding: window.innerWidth < 786 ? '0rem' : '20px', textAlign: window.innerWidth < 786 ? 'center' : 'center'}}><p style={paragraphStyle}><FontAwesomeIcon icon={faMoneyBillTransfer} style={{color:'white',width:'2rem',height:'2rem'}}/><br/>avg. 9,000 monthly tx</p></div>
            <div className='grid-item' style={{padding: window.innerWidth < 786 ? '0rem' : '20px', textAlign: window.innerWidth < 786 ? 'center' : 'center'}}><p style={paragraphStyle}><FontAwesomeIcon icon={faUsers} style={{color:'white',width:'2rem',height:'2rem'}}/><br/>14,000+ Users</p></div>
            <div className='grid-item' style={{padding: window.innerWidth < 786 ? '0rem' : '20px', textAlign: window.innerWidth < 786 ? 'center' : 'center'}}><p style={paragraphStyle}><FontAwesomeIcon icon={faLink} style={{color:'white',width:'2rem',height:'2rem'}}/><br/>11 Chains</p></div>
            <div className='grid-item' style={{padding: window.innerWidth < 786 ? '0rem' : '20px', textAlign: window.innerWidth < 786 ? 'center' : 'center'}}><p style={paragraphStyle}><FontAwesomeIcon icon={faComments} style={{color:'white',width:'2rem',height:'2rem'}} /><br/>500+ Groups</p></div>
        </div>
    </div>    
  );
}
export default Info2;