import './token.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faMoneyBill, faLock} from '@fortawesome/free-solid-svg-icons'; // Import the regular user icon

function Token() {
    return (
      <section className="tokensection" id="token">
       <div className='image-text-grid'> 
       <div className='grid-item'>
       <h1 style={{marginBottom: window.innerWidth < 786 ? '1.5rem' : '0', textAlign: window.innerWidth < 786 ? 'center' : 'left',  marginTop: '0px', fontSize:'4rem', letterSpacing:'0px', fontFamily:'CustomFont, CustomFont2'}}><span style={{ background: 'linear-gradient(to bottom right, rgb(162, 0, 255), rgb(73, 255, 228))', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>$DTOOLS</span><br />our<br />Token.</h1>
        </div>
       <div className='grid-item'>
       <p style={{marginBottom: window.innerWidth < 786 ? '1.5rem' : '11.25px'}}><h1 style={{ marginTop: '5px'}}>Listed on <qs>QuickSwap</qs></h1>The most trusted and leading DEX on Dogechain.<h1>Contract address</h1><span>0xb9fcaa7590916578087842e017078d7797fa18d0</span>
        <h1>Dogechain</h1>ID: 2000 - Currency: DOGE<br/>RPC: https://rpc.dogechain.dog</p>
        </div>
        <div className='grid-item'>
        <h1 style={{ marginTop: '0px',marginBottom: '2px' }}>Tokenomics:</h1>
        <div className='grid-container' style={{background: 'linear-gradient(to bottom right, rgb(162, 0, 255), rgb(73, 255, 228))', margin:'0px', padding:'0px'}}>
            <div className='grid-item'><p style={{color: 'black', fontSize: window.innerWidth < 786 ? '1.5rem' : '1.2rem', marginBottom:'0px'}}><FontAwesomeIcon icon={faCoins} />< br/>100,000,000<br />Tokens</p></div>
            <div className='grid-item'><p style={{color: 'black', fontSize: window.innerWidth < 786 ? '1.5rem' : '1.2rem', marginBottom:'0px'}}><FontAwesomeIcon icon={faMoneyBill} />< br/>3%<br />Buy & Sell< br/>Tax</p></div>
            <div className='grid-item'><p style={{color: 'black', fontSize: window.innerWidth < 786 ? '1.5rem' : '1.2rem', marginBottom:'0px'}}><FontAwesomeIcon icon={faLock} />< br/>LP< br/>locked until< br/>July 2024</p></div>
        </div>
        </div>
        <div className='grid-item'>
          <button className='max-width-button2' style={{marginTop: '0px', marginBottom:'12.93325px' }} onClick={() => window.location.href = 'https://explorer.dogechain.dog/token/0xB9fcAa7590916578087842e017078D7797Fa18D0/token-transfers'}>Explorer</button>  
          <button className='max-width-button2' style={{marginTop: '0px', marginBottom:'12.93325px'}} onClick={() => window.location.href = 'https://dexscreener.com/dogechain/0xd552a0d629a5188058e67239bd0e5afead755cd5'}>Chart</button>
          <button className='max-width-button' style={{marginTop:'13px'}}  onClick={() => window.location.href = 'https://quickswap.exchange/#/swap?currency0=ETH&currency1=0xB9fcAa7590916578087842e017078D7797Fa18D0&swapIndex=1'}>Swap</button>
          <button className='max-width-button' style={{marginTop:'12.93325px'}}  onClick={() => window.location.href = 'https://stake.dogetools.dog/'}>Stake</button>
        </div>
        </div>
      </section>
    );
  }

export default Token;

