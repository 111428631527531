// Navbar.js
import React from 'react';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faXTwitter, faMedium} from '@fortawesome/free-brands-svg-icons'

function Navbar() {
  document.addEventListener("DOMContentLoaded", function() {
    // Select the hamburger element
    var navLinks = document.querySelectorAll(".navbar a"); // Select all links inside navbar
    var hamburger = document.querySelector(".hamburger");

    // Add an event listener for the click event
    hamburger.addEventListener("click", function() {
        // Select the navbar element
        var navBar = document.querySelector(".navbar");
        
        // Toggle the 'active' class on the navbar element
        navBar.classList.toggle("active");
    });
        // Add event listeners to each link inside the navbar
        navLinks.forEach(function(link) {
          link.addEventListener("click", function() {
              var navBar = document.querySelector(".navbar");
              navBar.classList.remove("active"); // Remove 'active' class when a link is clicked
          });
      });
});


document.addEventListener('DOMContentLoaded', function() {
  const hamburger = document.querySelector('.hamburger');
  const navLinks = document.querySelectorAll('.navbar a');
  if (hamburger) {
    hamburger.addEventListener('click', function() {
      this.classList.toggle('active'); // Toggle the 'active' class on click
    });
  }
      // Add event listeners to each link inside the navbar
      navLinks.forEach(function(link) {
        link.addEventListener('click', function() {
            const navBar = document.querySelector('.navbar');
            navBar.classList.remove('active'); // Remove 'active' class when a link is clicked
            hamburger.classList.remove('active'); // Remove 'active' class from hamburger icon
        });
    });
});



document.addEventListener('DOMContentLoaded', function() {
  window.addEventListener('scroll', function() {
    var header = document.getElementById('myHeader');
    
    // Change the background color when scrolling down, adjust the condition as needed
    if (window.scrollY > 0) {
      header.style.background = 'rgba(0, 0, 0, 1)'; // New background color when scrolling
      header.style.color = 'white'
        
    } else {
      header.style.background = 'transparent'; // Original background color
      header.style.color = 'white'
    }
  });
});

  

  return (
    <header id="myHeader">
      <div className="logo">DogeTools</div>
      <div className="hamburger">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <nav className="navbar">
        <ul>
          <li><a href="https://t.me/DogeTools_tipbot" className="active">Get TipWallet</a></li>
          <li><a href="#info">Tools</a></li>
          <li><a href="#chain">Chain</a></li>
          <li><a href="#token">Token</a></li>
          <li><a href="#nfts">NFTs</a></li>
          <li><a href="#listing">Listing</a></li>
        </ul>
    <div className="navbar-content">
    <a href='https://x.com/DogeToolsDC' style={{textDecoration:'none'}}> <FontAwesomeIcon icon={faXTwitter} style={{marginLeft:'1rem',marginRight:'1rem', marginBlock:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>
       <a href='https://t.me/DogeToolsDCPortal' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faTelegram} style={{marginLeft:'1rem',marginRight:'1rem',marginBlock:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>       
       <a href='https://www.dextools.io/app/en/dogechain/pair-explorer/0xd552a0d629a5188058e67239bd0e5afead755cd5?t=1710520223819' style={{textDecoration:'none'}}><img src='images/dextools.svg' alt='' style={{marginLeft:'1rem',marginRight:'1rem',marginBlock:'0.8rem',color:'white',width:'3rem',height:'3rem',borderRadius:'15px'}}/></a>
       <a href='https://www.coingecko.com/en/coins/dtools' style={{textDecoration:'none'}}><img src='images/coingeckologo.png' alt='' style={{width:'3rem',height:'3rem',marginLeft:'1rem', marginRight:'1rem',marginBlock:'0.8rem',borderRadius:'15px'}}/></a>
       <a href='https://quickswap.exchange/#/swap?currency0=ETH&currency1=0xB9fcAa7590916578087842e017078D7797Fa18D0&swapIndex=1' style={{textDecoration:'none'}}><img src='images/quickswaplogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://medium.com/@DogeTools' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faMedium} style={{margin:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>

    </div>
    </nav>
    </header>
  );
}

export default Navbar;
