import './about.css';
function About() {
    return (
      <section className="aboutsection"  id="chain">
        <h1 style={{ marginBottom: '1rem', fontSize:'4rem', letterSpacing:'-2px',fontFamily:'CustomFont, CustomFont2'}}>On the chain of <br /><span style={{ background: 'linear-gradient(to bottom right, rgb(128, 0, 255),rgb(148, 40, 255), rgb(255, 200, 48), rgb(255, 187, 0))', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>Memes and Dreams</span>!</h1>
      <div className="image-text-grid">
      <div className='grid-item'>
<img src="images/dtoolsxdc.png" alt='' /></div>
<div className='grid-item'> <p><h1>DogeTools, Dogechain's BIGGEST helper</h1><br />As one of the first and greatest approaches of bringing utilities to DOGE, as Layer-2 chain, we recognized an immense potential building on this chain together with even greater communities!<br /><br />
<button className='max-width-button' style={{marginTop: '0px', marginBottom:'12.93325px', textDecoration:'none'}} onClick={() => window.location.href = 'https://www.dogechain.dog/'}>Explore Dogechain</button>
</p> </div>
      </div>
      </section>
    );
  }

export default About;