import './herosection.css';



const Herosection = () => {
    return (
      <section className="herosection">
        <h1 style={{ marginBottom: '1rem', fontWeight:'bold', letterSpacing:'-2px', fontFamily:'CustomFont' }}>The all in one solution for web3 <span style={{ background: 'linear-gradient(to bottom right, rgb(255, 85, 85), rgb(128, 0, 255))', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>community interaction</span>.</h1>
        <p>Tip, rain and burn <span>tokens</span>, <span>coins</span> and <span>NFTs</span> right through <k>Telegram</k></p>
        <div className="slider-buttons">
        <button style={{maxWidth:'50%', fontSize:'1.2rem', borderRadius:'50px'}} onClick={() => window.location.href = 'https://t.me/DogeToolsDCPortal'}>Join us</button>
        </div>
      </section>
    );
  }

export default Herosection;