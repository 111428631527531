import './about.css';
function Nfts() {
    return (
      <section className="aboutsection"  id="nfts">
        <h1 style={{ marginBottom: '1rem', fontSize:'4rem', letterSpacing:'-2px',fontFamily:'CustomFont, CustomFont2'}}>Much WOW, many<br /><span style={{ background: 'linear-gradient(to bottom right, rgba(195,34,114,1) 37%,rgba(253,179,45,1) 100%)', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}}>NFTs</span>!</h1>
      <div className="image-text-grid">
      <div className='grid-item'>
<img src="https://mint.dogetools.dog/nftgifnew2.gif" alt='' style={{borderRadius:'10px'}}/></div>
<div className='grid-item'> <p><h1>NFTs</h1>Our DOGE inspired NFT collection, decorated with different hats, glasses and tools. Featuring Pizza NFTs with prizes, rare Elon Musk and Marc Cuban motives.<br />
<h1>Staking</h1>Stake your NFTs for daily $DTOOLS rewards.<br />
<h1>$5000 wDOGE giveaway</h1>After fully minting out, the team will run a raffle between all NFT holders rewarding one lucky winner with $5000 worth of wDOGE.<br /><br />
<button className='max-width-button' style={{marginTop: '0px', marginBottom:'12.93325px', textDecoration:'none'}} onClick={() => window.location.href = 'https://mint.dogetools.dog/'}>Mint</button>
<button className='max-width-button' style={{marginTop: '0px', marginBottom:'12.93325px', textDecoration:'none'}} onClick={() => window.location.href = 'https://dogetools.vercel.app//'}>Stake</button>
<button className='max-width-button' style={{marginTop: '0px', marginBottom:'12.93325px', textDecoration:'none'}} onClick={() => window.location.href = 'https://app.withmantra.com/market/collection/0x92b995f34cbf637d59c66782e90dfec3ce3c202b?chain_id=2000&auctionType=fixed&sort=2&statuses=created'}>Explore on Marketplace</button>
</p> </div>
      </div>
      </section>
    );
  }

export default Nfts;