import './listing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
// images/arbitrumlogo.png

function Listing() {
    const [priceType, setPriceType] = useState('month');

    const handleTogglePriceType = () => {
      setPriceType(priceType === 'week' ? 'month' : 'week');
    };

    const [activeBot, setActiveBot] = useState('TipBot');
    const [activeList, setActiveList] = useState('default');
  
    const handleToggleBot = (bot) => {
      setActiveBot(bot);
    };
  
    const handleToggleList = (list) => {
      setActiveList(list);
    };
  
    
    return (
        <section className='listings' >
<div className="grid-item" style={{}}>
       <a href='https://arbitrum.io/' style={{textDecoration:'none'}} className="logo-link"><img src='images/arbitrumlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://www.avax.network/' style={{textDecoration:'none'}} className="logo-link"><img src='images/avaxlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://www.bnbchain.org/en/bnb-smart-chain' style={{textDecoration:'none'}} className="logo-link"><img src='images/bsclogo.png' alt='' style={{marginLeft:'1rem',marginRight:'1rem',marginBlock:'0.8rem',color:'white',width:'3rem',height:'3rem',borderRadius:'15px'}}/></a>
       <a href='https://www.base.org/' style={{textDecoration:'none'}} className="logo-link"><img src='images/baselogo.png' alt='' style={{filter:'invert(100%)',width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://cronos.org/' style={{textDecoration:'none'}} className="logo-link"><img src='images/cronoslogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://dogechain.dog/' style={{textDecoration:'none'}} className="logo-link"><img src='images/dclogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://ethereum.org/en/' style={{textDecoration:'none'}} className="logo-link"><img src='images/ethereumlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a> 
       <a href='https://www.kava.io/' style={{textDecoration:'none'}} className="logo-link"><img src='images/kavalogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://polygon.technology/' style={{textDecoration:'none'}} className="logo-link"><img src='images/polygonlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://pulsechain.com/' style={{textDecoration:'none'}} className="logo-link"><img src='images/pulsechainlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://shibarium.shib.io/' style={{textDecoration:'none'}} className="logo-link"><img src='images/shibariumlogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem'}}/></a>
</div>
<div className='grid-item' id='listing'><h1 style={{fontSize:'3rem', marginBottom:'0.5rem'}}>Transparent & adaptable pricing for <span>every team</span>'s ease.</h1></div>

      <div className='toggle-button-container'>
        <button className={`bot-button ${activeBot === 'TipBot' ? 'selected' : ''}`} onClick={() => { handleToggleBot('TipBot'); handleToggleList('default'); }}>
          TipBot
        </button>
        <button className={`bot-button ${activeBot === 'BurnBot' ? 'selected' : ''}`} onClick={() => { handleToggleBot('BurnBot'); handleToggleList('burn'); }}>
          BurnBot
        </button>
        <button className={`bot-button ${activeBot === 'NFT TipBot' ? 'selected' : ''}`} onClick={() => { handleToggleBot('NFT TipBot'); handleToggleList('nft'); }}>
          NFT TipBot
        </button>
      </div>
<div className='toggle-button-container'>
        <button className={`toggle-button ${priceType === 'week' ? 'selected' : ''}`} onClick={handleTogglePriceType}>
          Weekly
        </button>
        <button className={`toggle-button ${priceType === 'month' ? 'selected' : ''}`} onClick={handleTogglePriceType}>
          Monthly
        </button>
      </div>
<div className='grid2' style={{padding: window.innerWidth < 786 ? '1rem':'none', textAlign:"center"}}>
<div className={`grid-item1 ${activeBot}`}>
          <h1 style={{fontSize:'1.5rem', marginTop:'0px'}}>Basic</h1>
          <p>Ideal for testing or distributing native currencies.</p>
          <p>
          <div className="price">
          {activeBot === 'TipBot' && priceType === 'week' && '$0'}
            {activeBot === 'TipBot' && priceType === 'month' && '$0'}
            {activeBot === 'BurnBot' && priceType === 'week' && '$0'}
            {activeBot === 'BurnBot' && priceType === 'month' && '$0'}
            {activeBot === 'NFT TipBot' && priceType === 'week' && '$0'}
            {activeBot === 'NFT TipBot' && priceType === 'month' && '$0'}
          </div>
          {priceType === 'week' ? '/week' : '/month'}
          </p>
          <ul className={activeList}>
  {activeBot === 'TipBot' && (
      <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Tip listed currencies</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Rain listed currencies</li>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Try for free now</a>
      </>
  )}
{activeBot === 'BurnBot' && (
      <>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Burn listed tokens</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Check stats for listed tokens</li>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Try for free now</a>
      </>
)}

  {activeBot === 'NFT TipBot' && (
    <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Tip listed NFT collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Mint available collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Check data/socials around listings</li>
      <br/>
      <br/>      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Try for free now</a>
    </>
  )}
</ul>
    </div>
    <div className={`grid-item2 ${activeBot}`}>
        <h1 style={{fontSize:'1.5rem', marginTop:'0px'}}>Advanced</h1>
    <p>List your individual token as project or community.</p>
    <p>         
    <p>
    <div className="price">
            {activeBot === 'TipBot' && priceType === 'week' && '$40'}
            {activeBot === 'TipBot' && priceType === 'month' && '$160'}
            {activeBot === 'BurnBot' && priceType === 'week' && '$10'}
            {activeBot === 'BurnBot' && priceType === 'month' && '$40'}
            {activeBot === 'NFT TipBot' && priceType === 'week' && '$25'}
            {activeBot === 'NFT TipBot' && priceType === 'month' && '$100'}
          </div>
          {priceType === 'week' ? '/week' : '/month'}
          </p>
          </p>
          <ul className={activeList}>
          {activeBot === 'TipBot' && (
    <>
    {priceType === 'week' && (
    <>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Get your token listed</li>
    <br/>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Token available across all groups</li>
    <br/>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Pinned announcement in the DogeTools group</li>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Get listed</a>
  </>
    )}
      {priceType === 'month' && (
    <>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Get your token listed</li>
    <br/>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Token available across all groups</li>
    <br/>
    <li><FontAwesomeIcon icon={faCircleCheck} /> Pinned announcement in the DogeTools group</li>
    <br/>
    <li><FontAwesomeIcon icon={faCircleCheck} /> 2 Free ad space (48h)</li>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Get listed</a>
  </>
    )}
    </>
  )}
{activeBot === 'BurnBot' && (
  <>
    {priceType === 'week' && (
      <>
        <li><FontAwesomeIcon icon={faCircleCheck} /> <a href="#">List your own token</a></li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> <a href="#">Check stats for your token</a></li>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <br/>
        <br/>
        <br/>
        
        <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Get listed</a>
      </>
    )}
    {priceType === 'month' && (
      <>
        <li><FontAwesomeIcon icon={faCircleCheck} /> List your own token</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Check stats for your token</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> 1 free ad space (48h)</li>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>        <br/>
        <a href='https://t.me/DogeTools_tipbot' className="urlbutton">Get listed</a>
      </>
    )}
  </>
)}
{activeBot === 'NFT TipBot' && (
  <>
    {priceType === 'week' && (
      <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Tip your NFT collection</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Mint your collection (if mintable)</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Give your collection & socials more exposure</li>
      <br/>
      <br/>      
      <br/>
      <br/>
      <br/>
      <br/>
   
      <br/>
      </>
    )}
    {priceType === 'month' && (
      <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Tip listed NFT collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Mint available collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Give your collection & socials more exposure</li>
      <br/>      
      <li><FontAwesomeIcon icon={faCircleCheck} /> 1 free ad space (48h)</li>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      </>
    )}
    <a href='https://t.me/MissMollyB' className="urlbutton">Get listed</a>
  </>
)}
</ul>
    <br/>
    </div>
    <div className='grid-item3'><h1 style={{fontSize:'1.5rem', marginTop:'0px'}}>Permanent</h1>
    <p>Around for longterm?<br/>Grow with us as we build.</p>
    <p><div className="price">Contact us</div>one time payment</p>
    <ul className={activeList}>
  {activeBot === 'TipBot' && (
    <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Lifetime listing</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Token available across all groups</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Pinned announcement in the DogeTools group</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> 2 Free ad spaces monthly (48h)</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Get listed on BurnBot</li>
      <br/>
      <br/>
      <a href='https://t.me/MissMollyB' className="urlbutton">Reach out</a>   
    </>
  )}
{activeBot === 'BurnBot' && (
    <>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Lifetime listing</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Token available in all groups using the bot</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> Check stats for your token</li>
        <br/>
        <li><FontAwesomeIcon icon={faCircleCheck} /> 2 Free ad spaces monthly (48h)</li>
        <br/>
        <br/>
        <br/>

        <br/>
        <br/>
        <a href='https://t.me/MissMollyB' className="urlbutton">Reach out</a>
    </>
)}

  {activeBot === 'NFT TipBot' && (
    <>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Lifetime listing</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Tip listed NFT collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Mint available collections</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> Check data/socials around listings</li>
      <br/>
      <li><FontAwesomeIcon icon={faCircleCheck} /> 2 Free ad spaces monthly (48h)</li>
      <br/>
      <br/>
      <br/>
      <a href='https://t.me/MissMollyB' className="urlbutton">Reach out</a>
    </>
  )}
</ul>
    </div>
</div>
</section>
)

}

export default Listing;