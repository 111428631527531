// App.js
import React from 'react';
import Navbar from './components/navbar';
import Herosection from './components/herosection'
import Info2 from './components/Hello'
import About from './components/about'
import Token from './components/token'
import Team from './components/team'
import Footer from './components/footer'
import Listing from './components/listing'
import Nfts from './components/nfts'
import './App.css';

function App() {
  return (
    <div className='container'>
      <Navbar />
      <Herosection />
      <Info2 />
      <Listing />
      <About />
      <Token />
      <Nfts />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
