import './team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faXTwitter} from '@fortawesome/free-brands-svg-icons'

function Team() {
    return (
      <section className="teamsection" id="team">
       <h1 style={{marginBottom: window.innerWidth < 786 ? '1.5rem' : '1rem', marginBottom: '1rem', fontSize:'4rem', fontFamily:'CustomFont, CustomFont2'}}>Meet the <span style={{ background: 'linear-gradient(to bottom right, rgb(255, 0, 85), rgb(255, 73, 149))', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', letterSpacing:'-3px'}}>Team</span>.</h1>
       <div className="image-text-grid">
       <div className='grid-item' >
       <img src='images/teamimagemolly.png' alt='' style={{background:'rgb(222, 57, 115)',width:'100%',height:'100%',borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}/>
       <p style={{marginBottom: window.innerWidth < 786 ? '1.5rem' : '11.25px', textAlign:'left', paddingInline:'0rem', borderBottom:'2px solid  rgba(40, 40, 40, 0.62)', borderRight:'2px solid  rgba(40, 40, 40, 0.8)',borderBottomRightRadius:'15px',borderBottomLeftRadius:'15px'}}><h1 style={{ marginTop: '5px'}}>Molly</h1>
       Co-founder
       <br/>
       <br/>
       <br/><a href='https://x.com/MsMollyB13' style={{textDecoration:'none'}}> <FontAwesomeIcon icon={faXTwitter} style={{marginRight:'1rem', marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/> </a>
       <a href='https://t.me/MissMollyB' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faTelegram} style={{marginRight:'1rem',marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/></a>       
       </p></div>
       <div className="grid-item"><img src='images/teamimagepeach.png' alt='' style={{width:'100%',height:'100%',borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}/>
       <p  style={{background:'transparent', marginBottom: window.innerWidth < 786 ? '1.5rem' : '11.25px', textAlign:'left', paddingInline:'0rem', borderBottom:'2px solid  rgba(40, 40, 40, 0.8)', borderRight:'2px solid  rgba(40, 40, 40, 0.62)',borderBottomRightRadius:'15px',borderBottomLeftRadius:'15px'}}>
       <h1 style={{ marginTop: '5px'}}>Peach</h1>Socials and contests
       <br/>
       <br/>
       <br/><a href='https://x.com/PeachyPepe7' style={{textDecoration:'none'}}> <FontAwesomeIcon icon={faXTwitter} style={{marginRight:'1rem', marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/> </a>
       <a href='https://t.me/PeachyPepe7' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faTelegram} style={{marginRight:'1rem',marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/></a>       
       </p></div>
       <div className="grid-item"><img src='images/teamimagenick.png' alt=''style={{background:'rgb(57, 211, 222)',width:'100%',height:'100%',borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}/>
       <p style={{marginBottom: window.innerWidth < 786 ? '1.5rem' : '11.25px', textAlign:'left', paddingInline:'0rem', borderBottom:'2px solid  rgba(40, 40, 40, 0.62)', borderRight:'2px solid  rgba(40, 40, 40, 0.8)',borderBottomRightRadius:'15px',borderBottomLeftRadius:'15px'}}>
       <h1 style={{ marginTop: '5px'}}>Nick</h1>Tech dev
       <br/>
       <br/>
       <br/><a href='https://x.com/NothisisN1ck' style={{textDecoration:'none'}}> <FontAwesomeIcon icon={faXTwitter} style={{marginRight:'1rem', marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/> </a>
       <a href='https://t.me/NothisisNick' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faTelegram} style={{marginRight:'1rem',marginBlock:'1rem',color:'white',width:'1.5rem',height:'1.5rem'}}/></a>       
       </p></div>
       </div>
      </section>
    );
  }

export default Team;

