import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faXTwitter, faMedium} from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
        <footer>
       <span style={{fontSize:"2rem"}}>Socials & links:</span>
       <div className="grid-item" style={{}}>
       <a href='https://x.com/DogeToolsDC' style={{textDecoration:'none'}}> <FontAwesomeIcon icon={faXTwitter} style={{marginLeft:'1rem',marginRight:'1rem', marginBlock:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>
       <a href='https://t.me/DogeToolsDCPortal' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faTelegram} style={{marginLeft:'1rem',marginRight:'1rem',marginBlock:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>       
       <a href='https://www.dextools.io/app/en/dogechain/pair-explorer/0xd552a0d629a5188058e67239bd0e5afead755cd5?t=1710520223819' style={{textDecoration:'none'}}><img src='images/dextools.svg' alt='' style={{marginLeft:'1rem',marginRight:'1rem',marginBlock:'0.8rem',color:'white',width:'3rem',height:'3rem',borderRadius:'15px'}}/></a>
       <a href='https://www.coingecko.com/en/coins/dtools' style={{textDecoration:'none'}}><img src='images/coingeckologo.png' alt='' style={{width:'3rem',height:'3rem',marginLeft:'1rem', marginRight:'1rem',marginBlock:'0.8rem',borderRadius:'15px'}}/></a>
       <a href='https://quickswap.exchange/#/swap?currency0=ETH&currency1=0xB9fcAa7590916578087842e017078D7797Fa18D0&swapIndex=1' style={{textDecoration:'none'}}><img src='images/quickswaplogo.png' alt='' style={{width:'3rem',height:'3rem',margin:'1rem',borderRadius:'15px'}}/></a>
       <a href='https://medium.com/@DogeTools' style={{textDecoration:'none'}}><FontAwesomeIcon icon={faMedium} style={{margin:'1rem',color:'white',width:'3rem',height:'3rem'}}/></a>

       </div>
       <span style={{fontSize:'1.5rem'}}>Disclaimer:</span><span> Do your own research.</span><span style={{paddingInline:'20px'}}>You are solely responsible for your own moves and decisions and the evaluation and use of our products and services should be based on your own due diligence.</span>
       <p></p>
       <span>Copyright &copy; 2024 DogeTools</span>
       </footer>
    );
}
export default Footer;